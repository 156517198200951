import React, { useEffect, useState } from "react";
import { IconFacebook } from "./IconFacebook";
import { IconInstagram } from "./IconInstagram";
import { IconYoutube } from "./IconYoutube";
import { LogoWrapper } from "./LogoWrapper";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import FooterLiveMatches from "./getMatches/FooterLiveMatch/FooterLiveMatch";

const Footer = () => {
  const [thisAccount, setthisAccount] = useState([]);
  const [usersession, setUsersession] = useState("");
  const [watchalong, setWatchalong] = useState(false);
  const [currentPage, setCurrentPage] = useState(window.location.href);
  const [teams, setTeams] = useState<[any, any]>(["", ""]);
  const [matchName, setMatchName] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [matchId, setMatchId] = useState("");

  const [isMobile, setIsMobile] = useState(true);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
    const handleMediaQueryChange = (e) => {
      setIsMobile(e.matches);
    };
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const pagePages = [
    { path: "/", label: "Home" },
    { path: "/support", label: "Support" },
    { path: "/match", label: "Matches" },
    { path: "/about", label: "About" },
    { path: "/games", label: "Play" },
    { path: "/contact", label: "Contact" },
    { path: "/media", label: "Media" },
  ];

  const handleSignout = async () => {
    Cookies.remove("c8_session_token");
    window.location.href = "/";
  };
  async function getUser(session_token) {
    try {
      const response = await fetch(
        `${process.env.API_URL}/verifysession/${session_token}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY as string,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        if (data) {
          setUsersession(session_token);
          data.verifychannel = data.verifychannel.trim();
          setthisAccount(data);
        }
      } else {
        setUsersession("");
        return null;
      }
    } catch (error) {
      setUsersession("");
      return null;
    }
  }
  const fetchMatch = async (matchId) => {
    const res = await fetch(
      process.env.API_URL + `/fixturelist?match_id_contains=${matchId}&limit=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.API_KEY as string,
        },
      }
    );
    if (res.status !== 200) {
      console.error("not ok result");
      return;
    }

    const data = await res.json();
    const matches = data?.matches;
    if (!matches || !matches?.length) {
      console.info("no in progress matches found");
      return;
    }

    const match = matches[0];
    const teamsNames = match?.name ? match.name.split(" v ") : ["", ""];
    setTeams([teamsNames[0], teamsNames[1].split(",")[0]]);
    setMatchName(match.name);
  };
  const fetch_settings = async () => {
    const response = await fetch(`${process.env.API_URL}/settings/watchalong`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.API_KEY as string,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      if (data && data.upcoming) {
        fetchMatch(data.upcoming.match_id);
        setMatchId(data.upcoming.match_id);
        setVideoUrl(data.upcoming.url);
        setWatchalong(true);
      } else {
        setWatchalong(false);
      }
    } else {
      setWatchalong(false);
    }
  };
  useEffect(() => {
    const session_token = Cookies.get("c8_session_token");
    getUser(session_token);
    fetch_settings();
    return () => {};
  }, []);

  return (
    <div className="bg-header w-full">
      <div id="sitefooter" className="translate-y-[-50px]">
        <div className="flex md:justify-start justify-center md:items-start items-center h-[100px] md:left-[15px]">
          <LogoWrapper
            className="bg-[url(https://dyatkw7msrzcb.cloudfront.net/assets/image-1.png)] !w-[100px] !h-[100px]"
            to="/"
          />
        </div>

        <div className="mt-6 md:flex md:flex-row md:justify-around md:items-start w-full">
          <div
            id="col1"
            className="md:w-[300px] h-full flex flex-col items-center justify-start"
          >
            <div className="w-full h-full mt-[10px] mb-[20px] text-center md:text-left text-[24px] [font-family:'Anton',Helvetica] font-normal text-transparent">
              <div className="text-white uppercase">
                The home of cricket{" "}
                <span className="text-lime uppercase">livestream </span>
                media and <span className="text-lime uppercase"> games</span>
              </div>
            </div>

            {isMobile && (
              <Link
                to={`/matchdetail?id=${matchId}#`}
                className={`bg-main border-[1px] border-red border-solid p-1 md:h-[85px] lg:flex w-[95%] ${
                  watchalong === false ? "!hidden" : ""
                }`}
              >
                <div className="flex flex-col md:flex-col">
                  <div className="flex flex-row min-w-[23%] items-center gap-1">
                    <img
                      className="w-[16px] h-[11px]"
                      alt="Vector"
                      src="/img/red_camera.png"
                    />
                    <span className="text-red text-[12px]">LIVESTREAM</span>
                  </div>
                  <div>
                    <FooterLiveMatches
                      match_id={"1"}
                      matchTime={""}
                      homeTeamName={teams[0]}
                      awayTeamName={teams[1]}
                      videoUrl={videoUrl}
                    />
                  </div>
                </div>
              </Link>
            )}
          </div>

          <div className="flex justify-center items-start p-4">
            <div className="w-full  grid grid-cols-2 gap-2 font-anton text-main text-[16px]">
              {pagePages.map((item, index) => (
                <Link
                  key={item.path}
                  to={item.path}
                  onClick={() => window.scrollTo(0, 0)}
                  className="footerlink font-chakra p-2 font-400 text-[16px]"
                  style={{
                    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                    borderTop:
                      index < 2 ? "1px solid rgba(255, 255, 255, 0.2)" : "none",
                  }}
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>

          {!isMobile && (
            <Link
              to={`/matchdetail?id=${matchId}#`}
              className={`bg-main border-[1px] border-red border-solid p-2 md:h-[100px] md:w-[250px] lg:flex ${
                watchalong === false ? "!hidden" : ""
              }`}
            >
              <div className="flex md:flex-col w-full">
                <div className="flex flex-row min-w-[30%] items-center gap-[6px]">
                  <img
                    className="w-[16px] h-[11px]"
                    alt="Vector"
                    src="/img/red_camera.png"
                  />
                  <span className="text-red text-[14px]">LIVESTREAM</span>
                </div>
                <FooterLiveMatches
                  match_id={"1"}
                  matchTime={""}
                  homeTeamName={teams[0]}
                  awayTeamName={teams[1]}
                  videoUrl={videoUrl}
                />
              </div>
            </Link>
          )}

          <div className="md:w-[270px] flex flex-row justify-center items-center p-4">
            {usersession === "" ? (
              <div className="w-full flex flex-row justify-between items-center md:flex-col gap-2">
                <Link className="w-full" to="/signup">
                  <div className="font-anton text-main text-[16px] flex justify-center items-center bg-lime h-[40px] lg:h-[50px] lg:w-[197px]">
                    SIGN UP
                  </div>
                </Link>
                <Link className="w-full" to="/login">
                  <div className="font-anton text-main text-[16px] flex justify-center items-center bg-lime h-[40px] lg:h-[50px] lg:w-[197px]">
                    LOGIN
                  </div>
                </Link>
              </div>
            ) : (
              <div className="mt-[33px] w-full">
                <div
                  className="bg-lime h-[50px] md:w-full text-center cursor-pointer flex justify-center items-center"
                  onClick={handleSignout}
                >
                  <span className="font-anton text-main text-[16px]">
                    SIGN OUT
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/*               <div>
                    <div className='font-anton text-collection-1-c8-lime text-[24px]'>IPL 2024 TEAMS</div>
                    <div className='flex'>
                      <div>
                        <div className='font-chakra text-[16px] text-white pt-[10px]'>
                          <div className='border-solid border-t-[1px] border-maindark w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='teams?team=Gujarat Titans' className='footerlink flex'>
                              <div><ClubLogo teamName="Gujarat Titans" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Gujarat Titans</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-maindark w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='/teams?team=Chennai Super Kings' className='footerlink flex'>
                              <div><ClubLogo teamName="Chennai Super Kings" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Chennai Super Kings</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-maindark w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='/teams?team=Lucknow Super Giants' className='footerlink flex'>
                              <div><ClubLogo teamName="Lucknow Super Giants" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Lucknow Super Giants</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-maindark w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='/teams?team=Mumbai Indians' className='footerlink flex'>
                              <div><ClubLogo teamName="Mumbai Indians" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Mumbai Indians</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-maindark w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='/teams?team=Rajasthan Royals' className='footerlink flex'>
                              <div><ClubLogo teamName="Rajasthan Royals" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Rajasthan Royals</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-maindark w-[50px] md:w-[230px]'></div>
                        </div>
                      </div>

                      <div className='pl-[20px]'>
                        <div className='font-chakra text-[16px] text-white pt-[10px]'>
                          <div className='border-solid border-t-[1px] border-maindark w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='teams?team=Royal Challengers Bengaluru' className='footerlink flex'>
                              <div><ClubLogo teamName="Royal Challengers Bengaluru" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Royal Challengers Bengaluru</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-maindark w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='/teams?team=Kolkata Knight Riders' className='footerlink flex'>
                              <div><ClubLogo teamName="Kolkata Knight Riders" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Kolkata Knight Riders</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-maindark w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='/teams?team=Punjab Kings' className='footerlink flex'>
                              <div><ClubLogo teamName="Punjab Kings" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Punjab Kings</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-maindark w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='/teams?team=Delhi Capitals' className='footerlink flex'>
                              <div><ClubLogo teamName="Delhi Capitals" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Delhi Capitals</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-maindark w-[50px] md:w-[230px]'></div>
                          <div className='py-[5px] h-[36px]'>
                            <a href='/teams?team=Sunrisers Hyderabad' className='footerlink flex'>
                              <div><ClubLogo teamName="Sunrisers Hyderabad" height={'31px'} width={'31px'} /></div>
                              <div className='hidden md:inline pl-[10px] pt-[4px]'>Sunrisers Hyderabad</div>
                            </a>
                          </div>
                          <div className='border-solid border-t-[1px] border-maindark w-[50px] md:w-[230px]'></div>
                        </div>
                      </div>
                    </div>
                  </div> */}

      <div className="mt-[10px] w-[100%] h-[40px] justify-between items-center bg-[rgba(255,255,255,0.15)]">
        <div className="w-[100%] px-0">
          <div className="flex-grow flex items-center pl-[15px] [font-family:'Chakra_Petch',Helvetica] font-normal text-white text-[10px] tracking-[0] leading-normal justify-between">
            <div className="mt-[0px]">
              <Link className="menuLink" to="/terms">
                Terms
              </Link>
              &nbsp;&nbsp;
              <Link className="menuLink" to="/privacy">
                Privacy
              </Link>
              &nbsp;&nbsp;
              <Link className="menuLink" to="/terms#cookies">
                Cookies
              </Link>
              &nbsp;&nbsp;
            </div>

            <div id="social" className="w-[50%] flex justify-end">
              <div className="flex relative right-0">
                <a href="https://www.youtube.com/@Cricket8YT" target="_blank">
                  <div className="w-[40px] h-[40px] ml-[3px] flex justify-center items-center bg-[rgba(255,255,255,0.2)] relative">
                    <IconYoutube
                      className="relative h-[15px] w-[20px]"
                      iconYoutube="/img/icon-youtube.svg"
                    />
                  </div>
                </a>
                <a
                  href="https://www.facebook.com/Cricket8Official"
                  target="_blank"
                >
                  <div className="w-[40px] h-[40px] ml-[3px] flex justify-center items-center bg-[rgba(255,255,255,0.2)] relative">
                    <IconFacebook
                      className="relative"
                      iconFacebook="/img/icon-facebook.svg"
                    />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/Cricket8official/"
                  target="_blank"
                >
                  <div className="w-[40px] h-[40px] ml-[3px] flex justify-center items-center bg-[rgba(255,255,255,0.2)] relative">
                    <IconInstagram
                      className="relative"
                      iconInstagram="/img/icon-instagram.svg"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
